import React, { useEffect, useState } from 'react';
import Select, { components } from "react-select";
import { Spinner } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import axios from "../const/interceptorApi";
import { useNavigate, useLocation } from 'react-router-dom';
import "../assets/css/global.css"
import PolicyPopup from '../components/PolicyPopup';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import image1 from "../assets/image/regis2@1.png";
import image2 from "../assets/image/regis2@2.png";
import image3 from "../assets/image/regis1@3.png";
import line1 from "../assets/image/line1.png";
import line2 from "../assets/image/line2.png";
import { BASE_SIMPRO_LUMEN } from "../const/index"

const Register2 = () => {
  const location = useLocation();
  const { state } = location;
  const [showPolicyPopup, setShowPolicyPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState(state ? state.role : '');
  const [selectedPlan, setSelectedPlan] = useState(state ? state.plan : '');
  const [username, setUsername] = useState(state ? state.username : '')
  const [address, setAddress] = useState(state ? state.address : '')
  const [refferal, setRefferalCode] = useState(state ? state.refferal : '')
  const [userData, setUserData] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(state ? state.agreeToTerms : false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    type: '',
    message: ''
  });
  const { control, register, handleSubmit, formState: { errors } } = useForm();
  const images = [
    { url: image1, description: "Personal Account Information", color: '#67BEB5' },
    { url: line2, description: ".", color: 'white' },
    { url: image2, description: "Select a Plan", color: '#559FF8' },
    { url: line1, description: ".", color: 'white' },
    { url: image3, description: "Registration Successful", color: 'black' },
  ];

  useEffect(() => {
    if (!state || !state.fullName || !state.phone || !state.email) {
      navigate('/personal-account');
    } else {
      setUserData({
        fullName: state.fullName,
        phone: state.phone,
        email: state.email
      });
    }

    if (state && state.plan) {
      setSelectedPlan(state.plan);
    }
  }, [state]);

  const togglePolicyPopup = () => {
    setShowPolicyPopup(!showPolicyPopup);
  };

  const onSubmit = async (data) => {
    setUsername(data.username)
    setAddress(data.address)
    await handleSave();
  };

  const RoleOptions = [
    { value: 'Karyawan Swasta', label: 'Karyawan Swasta' },
    { value: 'Mahasiswa/Pelajar', label: 'Mahasiswa/Pelajar' },
    { value: 'Business Owner', label: 'Business Owner' },
  ];

  const PlanOptions = [
    { value: 'Free', label: 'Free' },
    { value: 'Basic', label: 'Basic' },
  ];

  const handleSnackbar = (type, message) => {
    setSnackbarData({
      type,
      message,
    });
    setOpenSnackbar(true);
    setInterval(() => {
      setOpenSnackbar(false);
    }, 4000)
  };

  const handleSave = async () => {
    if (!username.trim() || !selectedRole || !address.trim()) {
      return;
    }
    setLoading(true);
    const formData = {
      company_name: userData.fullName,
      full_name: userData.fullName,
      username,
      cluster: selectedRole.value,
      email: userData.email,
      address,
      phone_no: userData.phone,
      type_account: 'Personal',
      type_paket: selectedPlan.value
    }
    if (refferal && refferal !== '') {
      formData.refferal = refferal;
    }
    const USER_REGISTER = `${BASE_SIMPRO_LUMEN}/user-register`;
    const result = await axios.post(USER_REGISTER, formData)
      .then(res => res)
      .catch((error) => error.response);
    if (result && result.data && result.data.code == 200) {
      setLoading(false);
      handleSnackbar('success', result.data.message);
      navigate('/successful-registration', {
        state: {
          fullName: userData.fullName,
          phone: userData.phone,
          email: userData.email,
          username: username,
          password: result.data.generateRandom,
          address: address,
          plan: selectedPlan,
          role: selectedRole
        }
      });
    } else {
      handleSnackbar('error', result.data.message);
      setLoading(false);
    }
  }

  return (
    <>
      <Snackbar
        style={{ zIndex: 9999, maxWidth: 400 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarData.type} sx={{ width: '100%' }}>
          <AlertTitle>{snackbarData.type === 'success' ? 'Success' : 'Error'} Message</AlertTitle>
          {snackbarData.message}
        </Alert>
      </Snackbar>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', marginTop: '20px' }}>
            <div style={{ position: 'relative', marginBottom: '50px' }}>
              <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {images.map((image, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={image.url} alt={image.description} style={image.url === line1 || image.url === line2 ? { height: '5px' } : { height: '55px', width: '55px' }} />
                    <p style={{ color: image.color, textAlign: 'center', whiteSpace: 'nowrap', fontSize: '12px' }}>{image.description}</p>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ marginTop: '50px' }}>
              <Box component="div" sx={{ marginTop: '100px', width: '380px', mx: 'auto' }}>
                <div style={{ fontWeight: 'bold', fontSize: '27px', marginBottom: '10px' }}>
                  Select a Plan
                </div>
                <div style={{ fontSize: '16px', color: '#777B7E', paddingBottom: '10px' }}>
                  For industry regulatory purposes, your details are required. Please ensure your email is correct as the password will be sent via email.
                </div>
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                  <FormControl fullWidth>
                    <label htmlFor="username" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px' }}>
                      Username<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      className="hoverregistrasi"
                      {...register('username', { required: true })}
                      name="username"
                      id="username"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      defaultValue={state ? state.username : ''}
                      placeholder="Please enter username"
                      style={{
                        border: '1px solid gray',
                        borderRadius: '6px',
                        height: '50px',
                        paddingLeft: '20px',
                        fontSize: '12px',
                      }}
                    />
                    {errors.username &&
                      <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                        Username is Required
                      </span>
                    }
                  </FormControl>
                  <FormControl fullWidth>
                    <label htmlFor="Plan" style={{ color: "rgb(105, 105, 105)", fontWeight: 'bold', fontSize: '14px', marginTop: "5px" }}>
                      Choice Plan<span style={{ color: 'red' }}>*</span>
                    </label>
                    <Controller
                      name="selectedPlan"
                      control={control}
                      // defaultValue={state ? state.plan : selectedPlan}
                      defaultValue={selectedPlan}
                      rules={{ required: 'Please select a plan' }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={PlanOptions}
                          onChange={(val) => {
                            field.onChange(val);
                            setSelectedPlan(val);
                          }}
                          value={selectedPlan} // Set the value to selectedPlan
                          placeholder="Select your plan"
                          isDisabled={!!state?.plan} // Disable if state.plan is not null
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: '1px solid gray',
                              borderRadius: '6px',
                              height: '60px',
                              paddingLeft: '20px',
                              fontSize: '12px',
                            }),
                          }}
                        />
                      )}
                    />
                    {errors.selectedPlan &&
                      <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                        Please select a plan
                      </span>
                    }
                  </FormControl>
                  <FormControl fullWidth>
                    <label htmlFor="Role" style={{ color: "rgb(105, 105, 105)", fontWeight: 'bold', fontSize: '14px', marginTop: "5px" }}>
                      Position<span style={{ color: 'red' }}>*</span>
                    </label>
                    <Controller
                      name="Role"
                      control={control}
                      defaultValue={state ? state.role : ''}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={RoleOptions}
                          onChange={(val) => {
                            field.onChange(val);
                            setSelectedRole(val);
                          }}
                          placeholder="Select your Position"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: '1px solid gray',
                              borderRadius: '6px',
                              height: '60px',
                              paddingLeft: '20px',
                              fontSize: '12px',
                            }),
                          }}
                        />
                      )}
                      rules={{ required: true }}
                    />
                    {errors.Role &&
                      <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                        Please select a role
                      </span>
                    }
                  </FormControl>
                  <FormControl fullWidth>
                    <label htmlFor="address" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px', marginTop: "5px" }}>
                      Address<span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      className="hoverregistrasi"
                      {...register('address', { required: true })}
                      name="address"
                      id="address"
                      type="address"
                      placeholder="Please enter address"
                      value={address}
                      defaultValue={state ? state.address : ''}
                      onChange={(e) => setAddress(e.target.value)}
                      style={{
                        border: '1px solid gray',
                        borderRadius: '6px',
                        height: '50px',
                        paddingLeft: '20px',
                        fontSize: '12px',
                      }}
                    />
                    {errors.address &&
                      <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                        Address is required
                      </span>
                    }
                  </FormControl>
                  <FormControl fullWidth>
                    <label htmlFor="refferal" style={{ color: 'rgb(105, 105, 105)', fontWeight: 'bold', fontSize: '14px', marginTop: "5px" }}>
                      Refferal Code
                    </label>
                    <input
                      className="hoverregistrasi"
                      name="refferal"
                      id="refferal"
                      type="refferal"
                      placeholder="Please enter refferal"
                      value={refferal}
                      onChange={(e) => setRefferalCode(e.target.value)}
                      style={{
                        border: '1px solid gray',
                        borderRadius: '6px',
                        height: '50px',
                        paddingLeft: '20px',
                        fontSize: '12px',
                      }}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreeToTerms}
                        onChange={(e) => setAgreeToTerms(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I agree to&nbsp;
                        <small
                          onClick={togglePolicyPopup}
                          style={{ color: '#1976D2', cursor: 'pointer', marginTop: '-20px' }}
                        >
                          terms & conditions
                        </small>
                      </Typography>
                    }
                  />
                  <Box
                    type="bbox"
                    component="div"
                    display="flex"
                    justifyContent="center"
                    mb={2}
                  >
                    <div className="card-button">
                      <button
                        onClick={() => navigate('/personal-account', {
                          state: {
                            fullName: userData.fullName,
                            phone: userData.phone,
                            email: userData.email,

                            role: selectedRole ? selectedRole : state.role,
                            plan: selectedPlan ? selectedPlan : state.plan,
                            username: username,
                            address: address,
                            refferal: refferal,
                            agreeToTerms: agreeToTerms
                          }
                        })}
                        type="submit"
                        style={{ color: '#D8100B', border: '2px solid', width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', marginRight: '5px' }}
                      >
                        Back
                      </button>
                      <button
                        type="login"
                        style={{ width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', marginLeft: '5px' }}
                        disabled={!agreeToTerms || loading}
                      >
                        {
                          loading ? (
                            <Spinner color="light" size={'sm'}>
                              Loading...
                            </Spinner>
                          ) : (
                            "Sign Up"
                          )
                        }
                      </button>
                    </div>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </div>
        {showPolicyPopup && (
          <PolicyPopup isOpen={showPolicyPopup} togglePolicyPopup={togglePolicyPopup} />
        )}
      </div>
    </>
  );

};

export default Register2;
