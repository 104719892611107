import "../assets/css/global.css";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, FormControl } from "@mui/material";
// import "../assets/css/Login.css"
import image1 from "../assets/image/regis1@1.png";
import image2 from "../assets/image/regis1@2.png";
import image3 from "../assets/image/regis1@3.png";
import line1 from "../assets/image/line1.png";

const Register1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [userData, setUserData] = useState(null);
  const { register, handleSubmit, formState: { errors } } = useForm()
  useEffect(() => {
    if (state) {
      setUserData({
        role: state ? state.role : '',
        plan: state ? state.plan : '',
        username: state ? state.username : '',
        address: state ? state.address : '',
        refferal: state ? state.refferal : '',
        agreeToTerms: state ? state.agreeToTerms : ''
      });
    }
  }, [state]);

  const images = [
    { url: image1, description: "Personal Account Information", color: "#559FF8" },
    { url: line1, description: ".", color: "white" },
    { url: image2, description: "Select a Plan", color: "black" },
    { url: line1, description: ".", color: 'white' },
    { url: image3, description: "Registration Successful", color: 'black' },
  ];
  const onSubmit = (data) => {
    navigate('/plan-choice', {
      state: {
        fullName: data.fullName,
        phone: data.phone,
        email: data.email,

        role: userData ? userData.role : '',
        plan: userData ? userData.plan : '',
        username: userData ? userData.username : '',
        address: userData ? userData.address : '',
        refferal: userData ? userData.refferal : '',
        agreeToTerms: userData ? userData.agreeToTerms : ''
      }
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={{ width: '100%', marginTop: '20px' }}>
          <div style={{ position: 'relative', marginBottom: '50px' }}>
            <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              {images.map((image, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img src={image.url} alt={image.description} style={image.url === line1 || image.url === line1 ? { height: '5px' } : { height: '55px', width: '55px' }} />
                  <p style={{ color: image.color, textAlign: 'center', whiteSpace: 'nowrap', fontSize: '12px' }}>{image.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Box component="div" sx={{ marginTop: '10px', width: "380px", mx: "auto" }}>
            <div style={{ fontWeight: "bold", fontSize: "26px", marginBottom: "10px" }}>
              Personal Account Information
            </div>
            <div style={{ fontSize: "16px", color: "#777B7E", marginBottom: "20px" }}>
              For industry regulatory purposes, your details are required. Please ensure your email is correct as the password will be sent via email.
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth>
                <label htmlFor="name" className="coba" style={{ color: "rgb(105, 105, 105)", fontWeight: "bold", fontSize: "14px" }}>
                  Fullname<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  className="hoverregistrasi"
                  name="fullName"
                  id="fullName"
                  type="text"
                  defaultValue={state ? state.fullName : ''}
                  {...register("fullName", { required: true })}
                  placeholder="Please enter fullname"
                  style={{
                    border: '1px solid gray',
                    borderRadius: '6px',
                    height: '50px',
                    paddingLeft: '20px',
                    fontSize: '12px',
                  }}
                />
                {errors.fullName &&
                  <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                    Full Name is Required
                  </span>
                }
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="phone" style={{ color: "rgb(105, 105, 105)", fontWeight: "bold", fontSize: "14px", marginTop: "5px" }}>
                  Phone Number<span style={{ color: 'red' }}>*</span>
                </label>
                <div className="hoverregistrasi" style={{ borderRadius: "6px", height: "60px", fontSize: "12px", display: "flex", alignItems: "center" }}>
                  <input
                    {...register("phone", { required: true })}
                    type="tel"
                    id="phone"
                    name="phone"
                    defaultValue={state ? state.phone : ''}
                    placeholder="Please enter phone number, ex. 08...."
                    maxLength={12}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    style={{
                      border: '1px solid gray',
                      borderRadius: '6px',
                      height: '50px',
                      paddingLeft: '20px',
                      fontSize: '12px',
                    }}
                  />
                </div>
                {errors.phone && <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>Phone Number is Required</span>}
              </FormControl>
              <FormControl fullWidth>
                <label htmlFor="name" style={{ color: "rgb(105, 105, 105)", fontWeight: "bold", fontSize: "14px", marginTop: "5px" }}>
                  Email Address<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  name="email"
                  id="email"
                  type="email"
                  defaultValue={state ? state.email : ''}
                  placeholder='Please enter email'
                  {...register("email", {
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  })}
                  style={{
                    border: "1px solid gray",
                    borderRadius: "6px",
                    height: "60px",
                    paddingLeft: "20px",
                    fontSize: "12px",
                    marginBottom: '20px'
                  }}
                />
                {errors.email &&
                  <span className="error-message" style={{ color: 'red', fontSize: '12px' }}>
                    Enter a valid email address
                  </span>
                }
              </FormControl>
              <Box
                type="bbox"
                component="div"
                display="flex"
                justifyContent="center"
                mb={2}
                mt={2}
              >
                <div className="card-button">
                  <button
                    onClick={() => navigate('/register-choice', {
                      state: { plan: state ? state.plan : '' }
                    })}
                    type="submit"
                    style={{ color: '#D8100B', border: '2px solid', width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', marginRight: '5px' }}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    style={{ width: '450px', height: '55px', borderRadius: '6px', textTransform: 'none', fontSize: '16px', backgroundColor: 'red', color: 'white', marginLeft: '5px' }}
                  >
                    Save and Continue
                  </button>
                </div>
              </Box>
            </form>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default Register1;